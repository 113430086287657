import React, {Component} from 'react'
import Paper from 'material-ui/Paper'
import {Card, CardHeader, CardActions, CardText} from 'material-ui/Card'
import {grey300, grey800, grey100, teal400} from 'material-ui/styles/colors'
import TextField from 'material-ui/TextField'
import {ADMIN_PERMISSIONS, PROGRAMN_MANAGER, PROVIDER_PERMISSIONS, PERMISSIONS} from "../../utils/Constants";
import {RaisedButton} from "material-ui";
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';
import firebase from "../../App";
import Toggle from "material-ui/Toggle";
import hashes from 'jshashes'

export default class NewUser extends Component {
    constructor(props) {

        super(props)
        this.state = {
            user: {
                userLevel: 10,
                permissions: PERMISSIONS
            },
            permissions: {},
            status: null

        }
        this.users = []
    }

    componentDidMount() {
        const ref = this.props.firebase.database().ref("/USERS")
        ref.orderByChild("email").on('value', snapshot => {
            Object.keys(snapshot.val()).map(val => {
                console.log('val', snapshot.val()[val])
                this.users.push(snapshot.val()[val].email.toLowerCase())
            })

        })

    }

    addNewUser = () => {
        //Testing
        let user = this.state.user
        user.permissions = PERMISSIONS
        if (user.userLevel >= 99) {
            user.permissions = ADMIN_PERMISSIONS
        } else if (user.userLevel == 50) {
            user.permissions = PROGRAMN_MANAGER
        } else if (user.userLevel < 50) {
            user.permissions = PROVIDER_PERMISSIONS
        }


        if (!this.state.user.password || !this.state.user.email) {
            return
        } else {
            this.props.secondaryApp.auth().createUserWithEmailAndPassword(this.state.user.email, this.state.user.password).catch(error => {
                return error
            }).then(() => {
                user.password = new hashes.MD5().hex(user.password)
                this.props.firebase.database().ref(`/USERS/`).push(user)
                this.setState({
                    status: 'success', userLevel: 10,
                    permissions: PERMISSIONS
                })

            })
        }
    }

    renderCustomPermissiosn = () => {
        let perms = this.props.permissions
        return <div>
            <CardText expandable={true}>
                <h4>Custom Permissions:</h4>
                <div style={{display: 'flex', width: '50%', flexDirection: 'column'}}>
                    {Object.keys(PERMISSIONS || []).map(category => {
                        return <div key={category} style={{display: 'block'}}>
                            <div>
                                {category.toUpperCase()}
                                <hr/>
                                <div style={{}}>
                                    {Object.keys(PERMISSIONS[category] || []).map(permission => {
                                        return <Toggle key={category + permission} disabled={!perms?.edit}
                                                       toggled={
                                                           this.state.user?.permissions[category][permission]}
                                                       labelPosition={"right"}
                                                       label={permission.toUpperCase()} onToggle={() => {
                                        }
                                        } onToggle={(e, checked) => {
                                            //console.log(e)
                                            let {user} = this.state
                                            user.permissions[category][permission] = checked
                                            this.setState({user})
                                        }}
                                        />
                                    })}
                                </div>
                            </div>
                        </div>
                    })}
                </div>

            </CardText>

        </div>
    }

    render() {

        let emailValid = this.users.indexOf(this.state.email && this.state.email.toLowerCase()) < 0
        // console.log(emailValid, this.users)
        let formOkay = this.state.user.firstName && this.state.user.lastName && emailValid
        return <Paper style={{padding: "1rem"}}>
            <Card style={{
                background: teal400,
                color: grey100,
                padding: "1rem",
                flexDirection: 'column',
                display: 'flex'
            }}>
                <CardHeader>New User</CardHeader>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <TextField value={this.state.user.email} hintText={"Enter an Email Address"} type={"text"}
                               onChange={e => {
                                   let {user} = this.state
                                   user.email = e.target.value
                                   this.setState({user, status:null})
                               }} style={{color: grey100}}/>
                    <TextField value={this.state.password} hintText={"Enter a password"} type={"password"}
                               onChange={e => {
                                   let {user} = this.state
                                   user.password = e.target.value
                                   this.setState({user, status:null})

                               }}
                               style={{color: grey100}}

                    />
                    <TextField value={this.state.firstName} hintText={"First Name"} type={"text"} onChange={e => {
                        let {user} = this.state
                        user.firstName = e.target.value
                        this.setState({user, status:null})

                    }}

                               style={{color: grey100}}/>
                    <TextField value={this.state.lastName} hintText={"Last Name"} type={"text"} onChange={e => {
                        let {user} = this.state
                        user.lastName = e.target.value
                        this.setState({user, status:null})

                    }}
                               style={{color: grey100}}
                               textareaStyle={{color: grey100}}
                    />
                    <RadioButtonGroup name="userLevel" defaultSelected="10" onChange={e => {
                        let {user} = this.state
                        user.userLevel = e.target.value
                        this.setState({user, status:null})
                    }}>
                        <RadioButton
                            value="10"
                            label="Provider"

                        />
                        <RadioButton
                            value="50"
                            label="Program Manager"

                        />
                        <RadioButton
                            value="99"
                            label="Administrator"

                        />
                        <RadioButton
                            value="-1"
                            label="Custom"

                        />
                    </RadioButtonGroup>

                    <div>
                        {this.state.user.userLevel == '-1' && this.renderCustomPermissiosn()}
                    </div>
                </div>

                <CardActions>
                    {!emailValid ? <div>Please enter a valid email</div> :
                        formOkay &&
                        <RaisedButton style={{color: grey800}} primary={true}
                                      onClick={this.addNewUser} disabled={this.state.status != null}>Add</RaisedButton>
                    }
                    {this.state.status && this.state.status == 'success' ?
                        <div style={{color: grey100}}>User successfully added</div> : <div/>}

                </CardActions>

            </Card>

        </Paper>
    }
}