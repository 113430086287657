import React  from 'react'
import Grocery from 'material-ui/svg-icons/maps/local-grocery-store'
import Library from 'material-ui/svg-icons/maps/local-library'
import Restaurant from 'material-ui/svg-icons/maps/restaurant'
import Nature from 'material-ui/svg-icons/image/nature-people'
import Home from 'material-ui/svg-icons/action/home'
import Transportation from 'material-ui/svg-icons/maps/tram'
import Business from 'material-ui/svg-icons/communication/business'
import Convenience from 'material-ui/svg-icons/maps/local-convenience-store'
import School from 'material-ui/svg-icons/social/school'

export function CategoryIcon(props) {
        const {type, color} = props
        let ele = null
        switch (type.toLowerCase()) {
            case 'waiting room-office':
                ele = <Business color={color} />
                break;
            case 'restaurant':
                ele = <Restaurant color={color}/>;
                break;

            case 'dollar-general store':
                ele = <Library color={color}/>;
                break
            case 'la tienda de comestibles':
                ele = <Convenience color={color} />
                break;

            case 'grocery-market':


            case 'spanish grocery store':
                ele = <Grocery color={color}/>;
                break;

            case 'outdoors':
                ele = <Nature color={color}/>;
                break
            case "friend-relative's house":
                ele = <Library color={color}/>;
                break
            case "spanish friend-relative house":
                //console.log('returning home');
                ele = <Home color={color} />;
                break
            case 'faith based':
                ele = <Library color={color}/>;
                break
            case 'school':
                ele = <School color={color} />
                    break;
            case 'libraries':
                ele = <Library color={color}/>;
                break
            case 'spanish library':
                ele = <Library color={color}/>;
                break

            case('transportation'):
                ele = <Transportation color={color} />
                    break;

            default:
                ele = <Grocery color={color}/>;
                break


        }

        return ele

}