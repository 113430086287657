/**
 * Created by THOR on 4/29/2020.
 */

import React, {Component, Fragment} from 'react';
import {Card, CardActions, CardHeader, CardText} from 'material-ui/Card';
import TextField from "material-ui/TextField";
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import Save from 'material-ui/svg-icons/content/save'
import {teal300} from 'material-ui/styles/colors'

import CategoryPicker from '../../components/Categories/CategoryPicker'

export default class Prompt extends Component {

    constructor(props) {
        super(props)
        this.state = {
            name: props.name,
            text: props.text,
            category: this.props.category,
            changeCategory: false

        }
    }

    onDelete = () => {

    }
    changeChategory = category => {
        this.setState({category, changeCategory: false})
    }
    // updatePrompt = (key, prompt) => {
    //     let category = this.state.category
    //     let ref = this.props.firebase.database().ref(`/categories/${category}/${this.props.key}`)
    //     ref.set(prompt)
    //
    // }
    onSave = () => {
        let prompt = this.state
        delete prompt.changeCategory
        if (prompt.category != this.props.category) {
            let ref = this.props.firebase.database().ref(`/categories/${prompt.category}/prompts/`)

            delete prompt.category;
            ref.push(prompt)
        } else {
            let ref = this.props.firebase.database().ref(`/categories/${this.props.category}/prompts/${this.props.dbKey}`)
            delete prompt.category;
            ref.set(prompt)
        }
    }

    render() {
        const key = this.props.dbKey
        //console.log("<Prompt />", this.state.name, this.props.permissions)
        return <Card style={{padding: '1rem', width: '90%', marginBottom: '1rem'}}
                     containerStyle={{background: `${teal300}`, padding: "1rem"}}>
            <div>


                <TextField multiLine={true} defaultValue={this.state.name} key={`${key}.name`}
                           disabled={!this.props.permissions.edit}
                           floatingLabelText={"Prompt Name"}
                           onChange={e => {
                               //console.log(e.target.value)
                               this.setState({name: e.target.value})

                           }} style={{width: '90%', color: "#333"}}

                />


            </div>
            <div>

                <TextField multiLine={true} defaultValue={this.state.text} key={`${key}.text`}
                           floatingLabelText={"Prompt Text"}
                           disabled={!this.props.permissions.edit}
                           onChange={e => {
                               this.setState({text: e.target.value})


                           }}
                           style={{width: '90%'}}
                />
            </div>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                {this.state.changeCategory ?
                    <CategoryPicker firebase={this.props.firebase} onChange={this.changeChategory
                    }
                    /> : <FlatButton onClick={() => {
                        this.setState({changeCategory: true})
                    }}>Change Category</FlatButton>}
                {(this.state.name != this.props.name || this.state.text != this.props.text || this.state.category != this.props.category) &&
                <FlatButton onClick={this.onSave}><Save/></FlatButton>
                }
                {this.props.permissions && <FlatButton onClick={this.onDelete}>Delete</FlatButton>}
            </div>

        </Card>

    }


}