import React, {Component, Fragment} from 'react';

import Paper from 'material-ui/Paper'
import CircularProgress from 'material-ui/CircularProgress'
import FlatButton from 'material-ui/FlatButton'
import Add from 'material-ui/svg-icons/action/note-add'
import Prompt from './Prompt'
import {teal300} from "material-ui/styles/colors";
import TextField from "material-ui/TextField";
import CategoryPicker from "../../components/Categories/CategoryPicker";
import Save from "material-ui/svg-icons/content/save";
import {Card} from "material-ui/Card";

export default class Prompts extends Component {

    constructor(props) {
        super(props)

        this.state = {
            prompts: null,
            addPrompt: false,
        }
    }

    componentWillMount() {
        let category = this.props.match.params.category;
        //console.log(category)
        let ref = this.props.firebase.database().ref(`/categories/${category}`)

        ref.on('value', snapshot => {
            //console.log(snapshot.val())
            this.setState(snapshot.val())
        })
    }

    componentWillUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): void {

        let category = nextProps.match.params.category;
        if (category == this.props.match.params.category) {
            return
        }
        //console.log(category)
        let ref = this.props.firebase.database().ref(`/categories/${category}`)

        ref.on('value', snapshot => {
            //console.log(snapshot.val())
            this.setState(snapshot.val())
        })
    }

    updatePrompt = (key, prompt) => {
        let category = this.props.match.params.category;
        let ref = this.props.firebase.database().ref(`/categories/${category}/${key}`)
        ref.set(prompt)

    }
    onSave = ()=> {
        this.props.firebase.database().ref(`/categories/${this.props.match.params.category}/prompts`).push({name: this.state.name, text: this.state.text})
        this.setState({addPrompt: false, name: null, text: null})
    }
    renderAddPromptForm =()=> {
       return (<Card style={{padding: '1rem', width: '90%', marginBottom: '1rem'}} containerStyle={{background: `${teal300}`, padding: "1rem"}}>
            <div>

                <h2 style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between'
                }}>Name:

                    <TextField multiLine={true} defaultValue={this.state.name} key={`name`}
                               onChange={e => {
                                   //console.log(e.target.value)
                                   this.setState({name: e.target.value})

                               }} style={{width: '90%', color: "#333"}}

                    />

                </h2>

            </div>
            <div>

                <h3>Text:</h3>

                <TextField multiLine={true} defaultValue={this.state.text} key={`text`}
                           onChange={e => {
                               this.setState({text: e.target.value})


                           }}
                           style={{width: '90%'}}
                />
            </div>
            <div style={{display: 'inline-flex', alignItems: 'center'}}>

                {this.state.name && this.state.text && (
                <FlatButton onClick={this.onSave}><Save/></FlatButton>
                )}


            </div>

        </Card>
       )
    }
    render() {
        //console.log('<Prompts />', this.props, this.state)
        let category = this.props.match.params.category;
        if (!this.state.prompts) {
            return <CircularProgress thickness={10}/>
        }
        return <Paper style={{minHeight: '100vh', padding: '1rem'}}>
            <h2 style={{display: 'inline-flex', justifyContent: 'space-between', width: '90%'}}><span>{this.props.match.params.category.toUpperCase()}</span>
                {this.props.permissions.add && (
                <FlatButton style={{alignItems: 'center', display: 'inline-flex'}} onClick={()=>{this.setState({addPrompt: true})}}><Add /> Add New Prompt</FlatButton>
                )}
                    </h2>
            {this.state.addPrompt && this.renderAddPromptForm()}
            {Object.keys(this.state.prompts).map((prompt) => {
                //console.log(this.state.prompts[prompt]);

                return <Prompt key={prompt} dbKey={prompt} name={this.state.prompts[prompt].name} text={this.state.prompts[prompt].text} firebase={this.props.firebase} category={category} permissions={this.props.permissions}/>
            })}
        </Paper>
    }
}