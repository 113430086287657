/**
 * Created by THOR on 1/12/2019.
 */
import React, {Component, Fragment} from 'react'
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import Toggle from 'material-ui/Toggle';
import Paper from 'material-ui/Paper';
import DropDownMenu from 'material-ui/DropDownMenu';
import RaisedButton from 'material-ui/RaisedButton'
import MenuItem from 'material-ui/MenuItem';
import {teal200, grey900, teal300} from 'material-ui/styles/colors'
import DELETE from 'material-ui/svg-icons/content/delete-sweep'
import {PERMISSIONS} from '../../utils/Constants'
import ErrorComponent from "../ErrorComponent";


export default class User extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...props,
            user: null
        }


    }

    config = {
        apiKey: "AIzaSyAtH3rFvxqMSUTfxPdbcnyRdUDNLDnmyIk",
        authDomain: "expanded-league-678.firebaseapp.com",
        databaseURL: "https://expanded-league-678.firebaseio.com",
        projectId: "expanded-league-678",
        storageBucket: "expanded-league-678.appspot.com",
        messagingSenderId: "658810756226",

        "project_number": "658810756226",
        "firebase_url": "https://expanded-league-678.firebaseio.com",

        "project_id": "expanded-league-678",
        "storage_bucket": "expanded-league-678.appspot.com"

    }

    loadUser() {
        this.setState({
            id: this.props.match.params.user
        })

        let ref = this.props.firebase.database().ref(`/USERS/${this.props.match.params.user}`)

        ref.on('value', snapshot => {
            //console.log('user', snapshot.val())
            let user = snapshot.val()
            if (!user) {
                //console.log('no user')
                return
            }


            user.permissions = Object.assign({}, PERMISSIONS, user?.permissions);
            //console.log(user.permissions)
            this.setState({user})
        })
    }

    UNSAFE_componentWillMount() {
        this.loadUser();
        let ref = this.props.firebase.database().ref('PROGRAMS')
        //console.log(ref)
        ref.on('value', snapshot => {
            //console.log(snapshot.val())
            this.setState({programs: snapshot.val()})

        })

    }

    // updateUserProfile = () => {
    //     let user = firebase.auth().currentUser;
    // }
    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

        if (prevProps.match.params.user != this.props.match.params.user) {
            this.loadUser()
        }
    }

    updatePermission = (category, permission, value) => {
        let permissions = this.state.user.permissions
        permissions[category][permission] = !permissions[category][permission]
        let ref = this.props.firebase.database().ref(`USERS/${this.props.match.params.user}/permissions`).set(permissions)
    }
    programs = null
    handleClick = () => {

    }
    deleteUser = () => {
        console.log("Deleting user")
        let ref = this.props.firebase.database().ref(`USERS/`).child(`${this.props.match.params.user}`).remove();
        // location.
    }
    updateProgram = (event, index, value) => {
        //console.log('program', index, value)
        let user = Object.assign({}, this.state.user)
        user.program = this.state.programs[value]
        this.setState({user}, () => {
            this.props.firebase.database().ref(`USERS/${this.props.match.params.user}/program`).set(this.state.programs[value])
        })

    }
    updateFirstName = (event, index, value) => {

    }
    updateLastName = (event, index, value) => {

    }
    updateEmail = (event, index, value) => {

    }


    render() {
        let styles = {
            "display": "inline-block",
            "color": grey900,

            "borderRadius": "3px",
            width: "90%",
            margin: 10,
        }
        let style = {
            "borderRadius": "3px",

            "background": teal300,
            "color": grey900
        };
        let program = this.state.programs && this.state.programs.filter(program => {

            return this.state.user?.program?.id == program?.id
        })[0] || {name: "none"}

        //console.log('<UserComponent />', this.props, this.state)
        if (!this.state.user || !this.props.permissions?.view) {
            return <ErrorComponent>
                <h1>
                User does not exist or you lack the proper permissions
                </h1>
            </ErrorComponent>
        }
        const perms = this.props.permissions
        console.log("<UserComponent />", this.state.user)
        return <Paper zDepth={3} style={styles}>

            <Card expandable={true} style={style}>

                <CardHeader
                    titleStyle={{width: '100%'}}
                    title={<div style={{
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <span>{this.state.user?.firstName} {this.state.user?.lastName}</span>

                    </div>}
                    subtitle={`Email: ${this.state.user?.email}`}

                    style={{display: '100%'}}
                    showExpandableButton={true}
                    actAsExpander={true}
                >


                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'inline-flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <div style={{color: '#fff', marginTop: '.5rem'}}>Program</div>
                            <DropDownMenu value={program.id} onChange={this.updateProgram}>
                                {this.state.programs && this.state.programs.map(program => <MenuItem key={program.id}
                                                                                                     value={program.id}
                                                                                                     primaryText={program.name}/>)}
                            </DropDownMenu>

                        </div>

                    </div>
                </CardHeader>


                {this.props.permissions.view && (

                    <CardText >

                        <h2>Permissions:</h2>
                        <div style={{display: 'flex', width: '50%', flexDirection: 'column'}}>
                            {Object.keys(PERMISSIONS || []).map(category => {
                                return <div key={category} style={{display: 'block'}}>
                                    <div>
                                        {category.toUpperCase()}
                                        <hr/>
                                        <div style={{}}>
                                            {Object.keys(PERMISSIONS[category] || []).map(permission => {
                                                return <Toggle key={category + permission} disabled={!perms?.edit}
                                                               toggled={
                                                                   this.state.user?.permissions[category][permission]}
                                                               labelPosition={"right"}
                                                               label={permission.toUpperCase()} onToggle={() => {
                                                }
                                                } onToggle={(e, checked) => {
                                                    //console.log(e)
                                                    this.updatePermission(category, permission, checked)
                                                }}
                                                />
                                            })}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>


                    </CardText>

                )}
                <div style={{display: 'inline-flex', justifyContent: 'space-between', width: '100%'}}>
                <RaisedButton primary={true} onClick={() => {
                    var actionCodeSettings = {
                        url: `http://app.tat.weegoapp.org/?email=${this.state.user.email}`,
                        handleCodeInApp: false
                    };
                    this.props.firebase.auth().sendPasswordResetEmail(
                        this.state.user.email, actionCodeSettings)
                        .then(function () {
                            // Password reset email sent.
                        })
                        .catch(function (error) {
                            // Error occurred. Inspect error.code.
                        });


                }} label={'Send password reset email'}/>
                {this.props.permissions.delete && (
                    <RaisedButton secondary={true} onClick={this.deleteUser}label={"Delete"} />
                )}
                </div>
            </Card>
        </Paper>
    }
}
