import React, {Component, Fragment} from 'react';
import {red500, red100} from 'material-ui/styles/colors';
import {CategoryIcon} from '../Utils/CategoryIcons';
import GoogleMapReact from 'google-map-react';

const MarkerComponent = ({text, type}) => <div style={{borderRadius: '50%', backgroundColor: 'rgba(143, 0, 14, 0.62)', height: '8rem', width: '8rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'}}>
    <div style={{color: `#fff`,  alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
        <CategoryIcon type={type}  color="#FFF"/>

        <div>{text}</div>

    </div>

</div>


export default class WaypointsMap extends Component {


    render() {
        //console.log(this.props)

        let center = {
            lat: 0.0,
            lng: 0.0
        }

        let markers = Object.keys(this.props.waypoints).map(waypoint => {
            center.lat = Number(this.props.waypoints[waypoint].lat)
            center.lng = Number(this.props.waypoints[waypoint].lon)
            return <MarkerComponent lat={center.lat} lng={center.lng} text={this.props.waypoints[waypoint].name}
                                    type={this.props.waypoints[waypoint].category}/>
        })
        return (
            <div style={{height: '70vh', width: '100%'}}>
                <GoogleMapReact
                    bootstrapURLKeys={{key: "AIzaSyAtH3rFvxqMSUTfxPdbcnyRdUDNLDnmyIk"}}
                    defaultCenter={{lat: 0.0, lng: 0.0}}
                    center={center}
                    defaultZoom={14}
                    zoom={14}
                >
                    {markers}

                </GoogleMapReact>
            </div>
        )
    }
}