import React, {Component, Fragment, useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';

export default function PromptsMenu(props) {

    const [value, setValue] = useState('list')
    const [prompts, setPrompts] = useState(null)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    useEffect(() => {

        if(!prompts && !loading) {
            setLoading(true)
            let ref = props.firebase.database().ref("categories")
            window.ref = ref

            let surveys = []

            ref.on('value', snapshot => {
                setPrompts(Object.keys(snapshot.val()))
                setLoading(false)

            })
        }

    })

    const surveyButton = (category) => {

        return (
            <MenuItem key={category}
            >
                <button

                        onClick={()=> {
                            history.push(`/category/${prompts[category]}`)

                        }}
                      style={{color: "#FFF"}}>{prompts[category]}</button>

            </MenuItem>
        )


    }
    const   handleChange = (event, index, value) => {
        //console.log(value)


    }



        //console.log('<PromptsMenu />', this.state)
        if (!prompts) {
            return <CircularProgress/>
        }
        const styles = {
            block: {
                maxWidth: 250,
            },
            toggle: {
                marginBottom: 16,
            },
            thumbOff: {
                backgroundColor: '#ffcccc',
            },
            trackOff: {
                backgroundColor: '#ff9d9d',
            },
            thumbSwitched: {
                backgroundColor: 'red',
            },
            trackSwitched: {
                backgroundColor: '#ff9d9d',
            },
            labelStyle: {
                color: 'red',
            },
        };


        let categories = Object.keys(prompts).map(survey => {
            return surveyButton(survey)
        })


        return (
            <Fragment>


                <DropDownMenu onChange={handleChange} value={value}>


                    <MenuItem value="list" primaryText="Prompt Categories"/>
                    {categories}
                </DropDownMenu>

            </Fragment>

        )

}

PromptsMenu.propTypes = {
    addSurvey: PropTypes.func,
}