/**
 * Created by THOR on 7/21/2019.
 */
import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types'
import Paper from 'material-ui/Paper';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';


export default class BaseCard extends Component {

handleClick = () => {
    this.props.handleClick && this.props.handleClick()
}
    render() {
        const styles = {
            "display":"inline-block",
            "color": "#64B5F6",
            "borderRadius":"3px",
            minWidth: "50%",
            margin: 10,
        }

        let style = {
            "borderRadius":"3px",
            "padding":"2rem",
            "color": "#64B5F6"
        };

        return (
            <Paper zDepth={3} style={styles}>
                <div style={{justifyContent: 'flex-end', display: 'inline-flex', width: '100%', float: 'right'}} >
                    {this.props.menu && <Fragment>{this.props.menu}</Fragment>}
                {this.props.handleClick && (
                <button className='close' style={{float: 'right'}} onClick={this.handleClick}>x</button>
                )}
                </div>
                <Card expandable={true} style={this.props.style}>
                    <CardHeader title={this.props.title}
                                subtitle={this.props.subtitle}>
                        {this.props.headerChildren}
                    </CardHeader>
                    {this.props.children}
                </Card>
            </Paper>

        )
    }
}

BaseCard.propTypes = {
    style: PropTypes.object,
    title: PropTypes.node,
    subtitle: PropTypes.node,
    menu: PropTypes.node,
    headerChildren: PropTypes.node,
    children: PropTypes.node,


}