/**
 * Created by THOR on 5/26/2020.
 */
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Card} from "material-ui/Card";
import {teal200} from "material-ui/styles/colors";
import TextField from "material-ui/TextField";
import DropDownMenu from "material-ui/DropDownMenu";
import MenuItem from "material-ui/MenuItem";
import RaiseButton from 'material-ui/RaisedButton'
import IconButton from 'material-ui/IconButton'
import ADD from 'material-ui/svg-icons/content/add'
import REMOVE from 'material-ui/svg-icons/content/remove'

export default class QuestionComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {...this.props.question, modified: false}
    }

    render() {
        let {TEXT, TITLE, TYPE} = this.state
        //console.log('<QuestionComponent />', this.state)
        let OPTIONS = this.state.OPTIONS && [...this.state.OPTIONS]

        let ret = []
        switch (TYPE) {
            case "RADIO":
            case "CHECKBOX":
                //TODO: for editing, this should be just a list that you can add/remove from
                OPTIONS.map((option, ind) => {

                    ret.push(<div key={ind} style={{display: 'flex', justifyContent: 'space-between'}}><TextField
                        type={"text"}
                        defaultValue={option}
                        id={ind + option + Date.now()}
                        label={option}
                        onChange={e => {
                            let {OPTIONS} = this.state
                            OPTIONS[ind] = e.target.value
                            this.setState({OPTIONS, modified: true})

                        }}
                    />
                        <IconButton
                            tooltip={"REMOVE OPTION"}
                            onClick={() => {
                                let OPTS = this.state.OPTIONS.filter(opt => {
                                    return opt != option
                                })

                                //console.log('new options:', OPTS)
                                this.setState({OPTIONS: OPTS, modified: true})

                            }}><REMOVE/></IconButton>
                    </div>)
                })


                break;

            case "TEXT":
                break;

        }

        return (
            <div style={{width: '100%', margin: 'auto'}}>
                <Card style={{
                    background: teal200,
                    marginBottom: '1rem',
                    padding: '1rem',
                    justifyContent: 'space-between'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',

                        flexDirection: 'column'
                    }}>

                        <TextField id={TITLE} multiLine={true}
                                   defaultValue={TITLE}
                                   floatingLabelText={'Question Title'}
                                   onChange={e => {
                                       this.setState({TITLE: e.target.value, modified: true})
                                   }}

                        />
                    </div>
                    <br/>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',

                        flexDirection: 'column'
                    }}>

                        <TextField id={TEXT} multiLine={true}
                                   defaultValue={TEXT}
                                   floatingLabelText={'Question Text'}
                                   onChange={e => {
                                       this.setState({TEXT: e.target.value, modified: true})
                                   }}
                        />
                    </div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',

                        flexDirection: 'column',

                    }}>
                        <span>Type:</span>
                        <br/>
                        <DropDownMenu

                            value={TYPE} onChange={(event, ind, value) => {
                            //console.log(value)
                            this.setState({TYPE: value, modified: true}
                            )
                        }}


                        >
                            <MenuItem value={"RADIO"} primaryText={"Radio Buttons (Single Choice)"}/>
                            <MenuItem value={"CHECKBOX"} primaryText={"Checkbox (Multiple Choice)"}/>
                            <MenuItem value={"TEXT"} primaryText={"Short Answer (Text)"}/>

                        </DropDownMenu>
                    </div>
                    {TYPE != 'TEXT' &&
                    <div style={{
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}>
                        <h3>Options


                        </h3>
                        <IconButton

                            onClick={() => {
                                let OPTIONS = this.state.OPTIONS || []
                                OPTIONS.push('')
                                this.setState({OPTIONS, modified: true})

                            }}><ADD/></IconButton>

                    </div>
                    }
                    {ret}
                    {this.state.modified && <RaiseButton primary={true} onClick={() => {
                        this.props.handleUpdate(this.state)
                        this.setState({modified: false})
                    }
                    }><span style={{padding: '1rem'}}>SAVE</span></RaiseButton>}
                </Card>

            </div>
        )
    }


}
QuestionComponent.propTypes = {
    question: PropTypes.object.isRequired,
    firebase: PropTypes.object,
    handleUpdate: PropTypes.func.isRequired

}