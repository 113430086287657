import React, {Component, Fragment} from 'react';
import {Toolbar, ToolbarGroup, ToolbarSeparator, ToolbarTitle} from "material-ui/Toolbar";
import ParticipantMenu from '../Menus/ParticipantMenu'
import UserList from '../Menus/UserList'
import SurveyMenu from '../Menus/SurveyMenu'
import PromptsMenu from '../Menus/PromptsMenu'
import IconButton from "material-ui/IconButton";
import LOGOUT from 'material-ui/svg-icons/action/exit-to-app'


export default function MainAppBar(props) {

        console.log('history', props.history)
        if (!props.user) {
            return <div/>
        }
        let {permissions} = props.user
        //console.log(permissions)

        return (

            <Toolbar>
                <ToolbarTitle text={props.title}/>
                <ToolbarGroup>

                        <Fragment>
                            {permissions && permissions.user && permissions.user.view && (
                            <UserList
                                firebase={props.firebase}
                                addUser={props.addUser}
                                permissions={permissions.user}
                                history={props.history}
                            />
                            )}

                            {permissions && permissions.survey && permissions.survey.view && (
                                <SurveyMenu firebase={props.firebase}
                                            user={props.user}
                                            permissions={permissions.survey}
                                />
                            )}
                            {permissions && permissions.prompt && permissions.prompt.view && (
                                <PromptsMenu firebase={props.firebase}
                                             user={props.user}
                                             permissions={permissions.prompt}
                                />
                            )}
                        </Fragment>


                    {permissions && permissions.participant && permissions.participant.view && (
                        <ParticipantMenu apiKey="8675309" participantString="Participants"
                                         // session_token={this.state.session_token}
                                         loadPart={props.addPart}
                                         baseUrl={props.baseUrl}
                                         userLevel={props.userLevel}
                                         firebase={props.firebase}
                                         participants={props.participants}
                                         user={props.user}
                                         permissions={permissions.participant}
                        />
                    )}
                    <IconButton onClick={()=> {
                        props.firebase.auth().signOut().then(resp => {
                            window.location.reload()
                        });
                    }}><LOGOUT /> Logout</IconButton>

                </ToolbarGroup>
            </Toolbar>

        )

}

