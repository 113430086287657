import React, {Component, Fragment} from 'react'
import Paper from 'material-ui/Paper'
import RaisedButton from 'material-ui/RaisedButton'
import {Card, CardActions, CardHeader} from 'material-ui/Card';
import {cyan900, cyan800, cyan700, cyan600} from 'material-ui/styles/colors'
import TextField from 'material-ui/TextField'
export default class AddParticipant extends Component {
    constructor(props) {
        super(props)

        this.state = {
            deviceID: null,
            program: null,
        }
    }

    save = () => {
        //Push to /PARTICIPANTS/
        let part = {
            deviceID: this.state.deviceID,
            name: this.state.deviceID,
            active: true,
            enterPrompts: true,
            surveys: true,
            debug: false,
            program: this.state.program || 0
        }
        this.props.firebase.database().ref("PARTICIPANTS").push(part)
    }
    render() {
        //console.log(this.props)

        return(

        <Paper zDepth={3} style={{padding: "1rem"}}>
        <Card style={{background: cyan900, padding: "1rem"}}><CardHeader>Add Participant</CardHeader>
            <div>
            <TextField hintText={"Enter the Unique ID for the participant"} value={this.state.deviceID} onChange={(e)=> {
                this.setState({deviceID: e.target.value})

            }} style={{width: "100%"}}/>
            <div>You can Find the Unique for a participant in the settings menu of the installed mobile app. </div>
            </div>
            <CardActions><RaisedButton backgroundColor={cyan800} onClick={this.save}>Save</RaisedButton></CardActions>
        </Card>
        </Paper>
        )
    }
}