/**
 * Created by THOR on 5/27/2020.
 */


export const PERMISSIONS = {
    user: {
        view: false,
        add: false,
        edit: false,
        delete: false,

    },
    participant: {
        view: false,
        add: false,
        edit: false,
        delete: false,
        editWaypoint: false,
        addWaypoint: false,
        viewLogs: false,

    }
    ,
    survey: {
        view: false,
        add: false,
        edit: false,
        delete: false,

    },
    prompt: {
        view: false,
        add: false,
        edit: false,
        delete: false,

    },


}

export const ADMIN_PERMISSIONS = {
    user: {
        view: true,
        add: true,
        edit: true,
        delete: true,

    },
    participant: {
        view: true,
        add: true,
        edit: true,
        delete: true,
        editWaypoint: true,
        addWaypoint: true,
        viewLogs: true,

    }
    ,
    survey: {
        view: true,
        add: true,
        edit: true,
        delete: true,

    },
    prompt: {
        view: true,
        add: true,
        edit: true,
        delete: true,

    },

}

export const PROVIDER_PERMISSIONS = {
    user: {
        view: false,
        add: false,
        edit: false,
        delete: false,

    },
    participant: {
        view: true,
        add: true,
        edit: true,
        delete: true,
        editWaypoint: true,
        addWaypoint: true,
        viewLogs: true,

    }
    ,
    survey: {
        view: true,
        add: false,
        edit: false,
        delete: false,

    },
    prompt: {
        view: true,
        add: false,
        edit: false,
        delete: false,

    },

}

export const PROGRAMN_MANAGER = {
    user: {
        view: false,
        add: true,
        edit: false,
        delete: false,

    },
    participant: {
        view: true,
        add: true,
        edit: true,
        delete: true,
        editWaypoint: true,
        addWaypoint: true,
        viewLogs: true,

    }
    ,
    survey: {
        view: true,
        add: true,
        edit: true,
        delete: false,

    },
    prompt: {
        view: true,
        add: true,
        edit: true,
        delete: true,

    },

}