import React, {Component, Fragment, useState, useEffect} from 'react';
import FlatButton from 'material-ui/FlatButton';
import Toggle from 'material-ui/Toggle';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';
import {useHistory, useParams, useRouteMatch} from 'react-router-dom'
import AutoComplete from "material-ui/AutoComplete";


export default function ParticipantMenu(props) {
    let [participants, setParticipants] = useState(null)
    let [filter, setFilter] = useState(null)
    let [active, setActive] = useState(true)
    let [value, setValue] = useState('list')
    console.log('value', value)
    let history = useHistory()
    let [autoCompleteSource, setAutoCompleteSource] = useState(null)
    let participant = useRouteMatch({path: "/participant/:participant", strice: true, sensitive: false})?.params?.participant

    console.log("Participant", participant)
    const updateParticipants = (participants) => {
        setParticipants(participants)
        let autoCompleteSource = []
        for (let i in participants) {
            let item = participants[i]
            // console.log('val', item)
            autoCompleteSource.push({
                text: `${item.name}  ${item.deviceID}`,
                value: item.deviceID
            })
            setAutoCompleteSource(autoCompleteSource)

        }
    }
    useEffect(() => {
        if (!participants) {

            let ref = props.firebase.database().ref("PARTICIPANTS")

            ref.on('value', snapshot => {

                updateParticipants(snapshot.val())
                // setParticipants(snapshot.val())
                // setState({participants: snapshot.val()})


            })
        }


    })


    const participantButton = (obj) => {

        return (
            <MenuItem key={obj.deviceID}

            >

                <button style={{color: "#fff", padding: "1rem"}}

                        onClick={() => {
                            history.push(`/participant/${obj.deviceID}`)
                        }}
                >{obj.name}</button>

            </MenuItem>
        )


    }

    const updateFilter = e => {
        setFilter(e.target.value.length ? e.target.value : null)

    }
    const toggleActive = () => {
        setActive(!active)

    }


    //console.log('<ParticipantMenu />', state)
    if (!participants) {
        return <CircularProgress/>
    }
    const styles = {
        block: {
            maxWidth: 250,
        },
        toggle: {
            marginBottom: 16,
        },
        thumbOff: {
            backgroundColor: '#ffcccc',
        },
        trackOff: {
            backgroundColor: '#ff9d9d',
        },
        thumbSwitched: {
            backgroundColor: 'red',
        },
        trackSwitched: {
            backgroundColor: '#ff9d9d',
        },
        labelStyle: {
            color: 'red',
        },
    };

    let addUserButton = (props.permissions.add) ?
        <button onClick={() => {
            history.push(`/participant/add`)
        }} style={{color: "#fff", padding: "1rem"}}>Add Participant</button> : null;
    let viewReports = (props.userLevel > 50) ? <FlatButton label="View Reports"/> : null;
    let participantsButtons = []

    Object.keys(participants).length && Object.keys(participants).filter(participant => {

        let part = participants[participant]
        if (active == part.active) {

            participantsButtons.push(participantButton(part))
        }
    })

    if (!participants || !Object.keys(participants).length) {
        return <CircularProgress/>

    }


    //TODO: lets move this up
    return (
        <Fragment>
            <DropDownMenu onChange={value => {
                setValue(value)

            }}

                          value={'list'}

            >


                <div style={{padding: '1rem'}}>
                    {autoCompleteSource &&
                    <AutoComplete
                        hintText="Search for a participant"
                        dataSource={autoCompleteSource}
                        filter={AutoComplete.caseInsenitiveFilter}
                        fullWidth={true}
                        onNewRequest={(chosenRequest, index) => {

                            history.push(`/participant/${chosenRequest.value}`)

                        }}
                        // onUpdateInput={this.handleUpdateInput}
                    />
                    }
                    <Toggle
                        label={`${active ? 'Active' : 'Inactive'}`}
                        toggled={active}
                        labelPosition="right"
                        style={styles.toggle}
                        onToggle={toggleActive}
                    />
                    {addUserButton}
                    {/*{viewReports}*/}
                </div>


                <MenuItem value="list" primaryText="Participants"/>
                {participantsButtons}
            </DropDownMenu>

        </Fragment>

    )

}
