/**
 * Created by THOR on 1/12/2019.
 */
import React, {Component, Fragment, useState, useEffect} from 'react'
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';
import {Link, useHistory} from 'react-router-dom'

import AutoComplete from 'material-ui/AutoComplete'
import {FlatButton} from "material-ui";

export default function UserList(props) {
    let [dataSource, setDataSource] = useState(null)
    let [autoCompleteSource, setAutoCompleteSource] = useState(null)
    let [users, setUsers] = useState(null)
    let history = useHistory()
    const handleUpdateInput = (value) => {
        setDataSource([
                value,
                value + value,
                value + value + value,
            ],
        );
    }

    let updateUsers = users => {
        setUsers(users)
    }
    useEffect(() => {
        // props.firebase.auth()
        if (users) {
            return
        }
        let ref = props.firebase.database().ref("USERS")
        let autoCompleteSource = []
        ref.on('value', snapshot => {

            for (let i in snapshot.val()) {
                let item = snapshot.val()[i]
                // console.log('val', item)
                autoCompleteSource.push({
                    text: `${item.firstName}  ${item.lastName}  ${item.email}`,
                    value: i
                })

            }
            updateUsers(snapshot.val())
            setAutoCompleteSource(autoCompleteSource)

        })

    })
    const userButton = (obj, key,) => {

        return (
            <MenuItem key={key} value={obj.id}

            >

                <button onClick={() => {
                    history.push(`/user/${key}`)
                }}
                        style={{color: "#fff", padding: "1rem"}}>{obj.email}</button>
            </MenuItem>

        )


    }

    if (!users) {
        return <CircularProgress/>
    }
    let usersButtons = []

    usersButtons && Object.keys(users).map((key) => {

        usersButtons.push(userButton(users[key], key))
    })


    return <Fragment>

        <DropDownMenu value={'Users'}>
            <MenuItem value={'Users'} primaryText={"Users"}/>

            <AutoComplete
                hintText="Filter Users"
                dataSource={autoCompleteSource}
                filter={AutoComplete.caseInsenitiveFilter}
                fullWidth={true}
                onNewRequest={(chosenRequest, index) => {


                    history.push(`/user/${chosenRequest.value}`)


                }}

            />

            {props.permissions.add &&


                <FlatButton onClick={()=> {
                history.push(`/user/add`) }} style={{color: "#fff"}}>Add New User</FlatButton>

            }


            {usersButtons}
        </DropDownMenu>

    </Fragment>

}