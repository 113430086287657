import React, {Component} from 'react'
import {CategoryIcon} from "../Utils/CategoryIcons";
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

export default class CategoryPicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categories: []
        }
    }

    componentWillMount(): void {
        this.props.firebase.database().ref("categories").on('value', snapshot => {
            this.setState({categories: Object.keys(snapshot.val())})
        })
    }

    render() {
        const style = {
            display: 'flex',
            flexDirection: 'column',

            // margin: '16px 32px 16px 0',
        };


        return (<div>
            <Paper style={style}>
                <Menu>
                    {this.state.categories.map(item => {
                        return <MenuItem style={{width: '90%'}}>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}
                                 onClick={this.props.onChange.bind(this, item)}>

                                <CategoryIcon type={item} color={"#fff"}/>{' '}{item.toUpperCase()}</div>
                        </MenuItem>
                    })}
                </Menu>
            </Paper>

        </div>)
    }
}
