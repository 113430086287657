import React, {Component} from 'react';
import WaypointList from '../Waypoints/WaypointList.js'

import Waypoint from '../Waypoints/Waypoint'
import {Card, CardActions, CardHeader} from 'material-ui/Card';
import Cancel from 'material-ui/svg-icons/navigation/cancel'
import Save from 'material-ui/svg-icons/content/save'
import Delete from 'material-ui/svg-icons/content/delete-sweep'
import Edit from 'material-ui/svg-icons/image/edit'
import FlatButton from 'material-ui/FlatButton'
import TextField from 'material-ui/TextField';
import Toggle from 'material-ui/Toggle';
import Paper from 'material-ui/Paper';
import Dialog from 'material-ui/Dialog'
import CircularProgress from 'material-ui/CircularProgress';
import Drawer from 'material-ui/Drawer';


import {cyan800} from 'material-ui/styles/colors';


export default class Participant extends Component {


    constructor(props) {
        super(props)

        this.state = {
            editName: false,
            editDeviceID: false,
            showPrompts: true,
            deleteDialog: false,
        }

    }

    loadParticipant() {
        console.log("Loading participant")
        //TODO: refactor this to functional component
        this.setState({
            id: this.props.match.params.participant ||
                (this.props.match && this.props.match.params && this.props.match.params.number)
        })

        let participant = this.props.match.params.participant || this.props.match.params.participant
        console.log("Participant", participant)

        let ref = this.props.firebase.database().ref("PARTICIPANTS/")
        ref.on('value', snapshot => {
            let parts = snapshot.val()
            let key = null
            let part = Object.keys(parts).filter(p => {
                console.log(parts[p].deviceID, participant, parts[p].deviceID == participant)
                if (parts[p].deviceID == participant) {

                    console.log(parts[p])
                    key = p
                }
                return parts[p].deviceID == participant
            })[0]


            part = this.props.firebase.database().ref(`PARTICIPANTS/${part}`)
            part.on('value', snapshot => {

                this.setState({...snapshot.val(), key})
            })
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {

        if (this.props.match.params.participant != prevProps.match.params.participant) {
            //console.log('updating')
            this.loadParticipant()
        }
        if (this.props.match.params.waypointid != prevProps.match.params.waypointid) {
            this.setState({showPrompts: true})
        }
    }

    UNSAFE_componentWillMount() {

        this.loadParticipant()
    }

    remove = () => {
        //TODO: delete from dom

        // ReactDOM.unmountComponentAtNode(document.getElementById('page'));
    }

    handleClick = () => {
        // this.props.removePart(this, this.props)
    }
    toggleEditName = () => {
        this.setState({editName: !this.state.editName})

    }
    saveName = (event, data) => {
        this.setState({editName: false});
        this.updateString('name');

    }

    saveDeviceID() {
        this.setState({editDeviceID: false}, function () {
            this.updateString('deviceID');

        });
    }

    updateProp = (prop) => {
        //console.log(this.props);
        let part = this.props.firebase.database().ref(`PARTICIPANTS/${this.state.key}`)
        let update = null
        part.on('value', snapshot => {
            //console.log(snapshot.val())
            update = snapshot.val()
        })
        update[prop] = !update[prop]
        this.props.firebase.database().ref(`PARTICIPANTS/${this.state.key}`).set(update)
    }
    updateString = value => {

        //console.log(value, this.state)
        let part = this.props.firebase.database().ref(`PARTICIPANTS/${this.state.key}`)
        let update = []
        part.on('value', snapshot => {

            update = snapshot.val()
            update[value] = this.state[value]

            this.props.firebase.database().ref(`PARTICIPANTS/${this.state.key}`).set(update)
        })

    }
    handleNameChange = (event) => {
        if(event.target.value.length < 1) {
            return;
        }
        this.setState({name: event.target.value})
    }
    handleDeviceIDChange = (event) => {
        if(event.target.value.length < 1) {
            return;
        }
        this.setState({deviceID: event.target.value})
    }
    handleActiveToggle = () => {

        this.setState({active: (Number(this.state.active) == 0) ? 1 : 0}, function () {
            this.updateProp('active');
        });

    }
    handleEnterPromptsToggle = () => {
        this.setState({enterPrompts: (this.state.enterPrompts == 1) ? 0 : 1}, function () {
            this.updateProp('enterPrompts');
        });

        // this.setState({prop: !this.state.prop});

    }
    handleExitPromptsToggle = () => {
        this.setState({exitPrompts: (this.state.exitPrompts == 1) ? 0 : 1}, function () {
            this.updateProp('exitPrompts');
        });


    }
    handleSurveysToggle = () => {
        this.setState({surveys: (this.state.surveys == 1) ? 0 : 1}, function () {
            this.updateProp('surveys')
        });


    }
    handleDebugModeToggle = () => {
        this.setState({debugMode: (this.state.debugMode == 0) ? 1 : 0}, function () {
            this.updateProp('debugMode');
        });


    }
    toggleEditDeviceID = () => {
        this.setState({editDeviceID: !this.state.editDeviceID})
    }
    handleClose = () => {
        // //console.log("handleClose")
        this.setState({deleteDialog: false})
    }
    deletePart = () => {
        this.props.firebase.database().ref(`PARTICIPANTS/`).child(this.state.key).remove();
        this.handleClose()
        window.location.pathname="/"


    }
    loadWaypoint = (value) => {

        this.setState({waypoint: value})

    }
    deleteDialog = () => {
        //TODO: implement a modal, or some other element?
        ;
        let title = "Delete " + this.state.name

        let actions = [
            <FlatButton label="Cancel" key="cancel " onClick={this.handleClose}/>,
            <FlatButton label="Confirm" key="confirm" onClick={this.deletePart}/>

        ]
        return (
            <div>
                <Dialog title={title} actions={actions} modal={true} open={true}>

                </Dialog>
            </div>
        )
    }


    togglePromptDetails = () => {
        this.setState({showPrompts: !this.state.showPrompts})

    }

    render() {
        const {waypoint} = this.props.match.params
        //console.log(this.props.match)
        //console.log(waypoint)
        if (!this.state.deviceID) {
            return <CircularProgress/>
        }

        let styles = {
            "display": "inline-block",
            "color": "#64B5F6",
            "borderRadius": "3px",
            width: "95%",
            margin: 10,
        }

        let mapStyle = {
            width: "100%"
        };
        let style = {
            "borderRadius": "3px",
            "padding": "2rem",
            "color": "#64B5F6"
        };

        const editName = this.state.editName && this.state.name ?
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <TextField value={this.state.name} onChange={this.handleNameChange} key={"name-text"} />
                <FlatButton icon={<Save/>} onClick={this.saveName}/>
                <FlatButton icon={<Cancel/>} onClick={this.toggleEditName}/>
            </div>
            :
            <div>{this.state.name} {this.props.permissions.edit && <FlatButton icon={<Edit/>} labelPosition="before" onClick={this.toggleEditName}/>}
            </div>


        const editDeviceId = this.state.deviceID && this.state.editDeviceID ?
            <div>
                <TextField value={this.state.deviceID} onChange={this.handleDeviceIDChange}/>
                <FlatButton icon={<Save/>} onClick={this.saveDeviceID}/>
                <FlatButton icon={<Cancel/>} labelPosition="before" onClick={this.toggleEditDeviceID}/>
            </div>
            :
            <div>
                Unique ID:{this.state.deviceID}
                {this.props.permissions.edit && <FlatButton icon={<Edit/>} labelPosition="before" onClick={this.toggleEditDeviceID}/>}
            </div>


        return (
            <Paper zDepth={3} style={styles}>
                {this.state.deleteDialog && this.deleteDialog()}
                <Card expandable={true} style={style}>
                    <CardHeader
                        title={editName}
                        subtitle={editDeviceId}>


                    </CardHeader>


                    <div style={{display: 'inline-flex', justifyContent: 'space-between', width: '100%'}}>
                        <Toggle toggled={this.state.active == "1"}
                                labelPosition={"right"}
                                disabled={!this.props.permissions.edit}
                                label="Active" onToggle={this.handleActiveToggle}
                        />
                        <Toggle toggled={this.state.enterPrompts == 1}
                                labelPosition={"right"}
                                disabled={!this.props.permissions.edit}
                                label="Enter Prompts" onToggle={this.handleEnterPromptsToggle}
                        />
                        <Toggle toggled={this.state.exitPrompts == 1}
                                labelPosition={"right"}
                                disabled={!this.props.permissions.edit}
                                label="Exit Prompts" onToggle={this.handleExitPromptsToggle}
                        />
                        <Toggle toggled={this.state.surveys == 1}
                                labelPosition={"right"}
                                disabled={!this.props.permissions.edit}
                                label="Surveys" onToggle={this.handleSurveysToggle}
                        />
                        <Toggle toggled={this.state.debugMode == 1}
                                labelPosition={"right"}
                                disabled={!this.props.permissions.edit}
                                label="Debug Mode" onToggle={this.handleDebugModeToggle}
                        />
                    </div>


                    <WaypointList participant={this.state.id} apiKey="8675309"
                                  deviceID={this.state.id}
                                  loadWaypoint={this.loadWaypoint} baseUrl={this.props.baseUrl}
                                  firebase={this.props.firebase}
                                  permissions={this.props.permissions}
                                  match={this.props.match}
                    />


                    {this.props.match.params.waypoint &&
                    <Drawer open={this.state.showPrompts} openSecondary={this.state.showPrompts}
                            containerStyle={{width: `${this.state.showPrompts ? '50vw' : '0vw'}`, background: cyan800}}
                            style={{padding: '1rem'}}>
                        <div>
                            <button onClick={() => {
                                this.setState({showPrompts: !this.state.showPrompts})
                            }}><Cancel/></button>
                            <Waypoint waypoint={this.props.match.params.waypointid}

                                      participant={this.state.id}
                                      permissions={this.props.permissions}
                                      firebase={this.props.firebase}/>
                        </div>
                    </Drawer>
                    }
                    <CardActions>

                        {this.props.permissions?.delete && (
                        <FlatButton className='btn btn-danger' onClick={()=> {this.setState({deleteDialog: true})}}><Delete/></FlatButton>
                        )}

                    </CardActions>
                </Card>
            </Paper>


        );
    }

}
