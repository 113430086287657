/**
 *
 * @param ref should be the full path, e.g. /participants/participant/key-to-be-updated
 * @param value - String
 */
export const updateString = (ref, value) => {
    return new Promise((resolve, reject) => {
        if(!ref || !value) {
            reject('not enough shit to update and shit')
        }

        ref.set(value)
        resolve('foo')

    })

}