/*global google*/
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import WaypointLog from './WaypointLog'
// import {Map, Marker} from 'google-maps-react';
import CircularProgress from 'material-ui/CircularProgress';
import {CategoryIcon} from "../Utils/CategoryIcons";
import {updateString} from "../Utils/RecordUTILS";
import TextField from "material-ui/TextField";
import FlatButton from "material-ui/FlatButton";
import {RaisedButton} from "material-ui";
import Save from "material-ui/svg-icons/content/save";
import Cancel from "material-ui/svg-icons/navigation/cancel";
import Edit from "material-ui/svg-icons/image/edit";
import CategoryPicker from "..//Categories/CategoryPicker";


export default class Waypoint extends Component {

    constructor(props) {
        super(props)

        this.state = {
            name: null,

            lat: null,
            lon: null,
            editName: false,
            chooseCategory: false

        }

    }

    propTypes = {
        firebase: PropTypes.object,

    }
    toggleEditName = () => {
        this.setState({editName: !this.state.editName})

    }
    toggleEditCategory = () => {
        this.setState({chooseCategory: !this.state.chooseCategory})
    }

    componentWillMount(): void {

        this.props.firebase.database().ref(`WAYPOINTS/${this.props.waypoint}`).on('value', snapshot => {

            this.setState(snapshot.val())
        })

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (prevProps.waypoint !== this.props.waypoint) {

            this.updateMap()
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.waypoint != this.props.waypoint) {
            this.updateMap(nextProps)
        }
    }

    handleNameChange = (event) => {
        this.setState({name: event.target.value})
    }

    updateMap() {
        this.props.firebase.database().ref(`WAYPOINTS/${this.props.waypoint}`).on('value', snapshot => {
            this.setState(snapshot.val())
        })
    }

    renderLog() {
        return <WaypointLog id={this.props.participant} firebase={this.props.firebase}
                            waypointId={this.props.waypoint}
                            db={this.props.firebase.database().ref(`waypointLog/${this.props.participant}/${this.props.waypoint}`)}
                            promptsRef={this.props.firebase.database().ref(`/categories/`)}
                            category={this.state.category}
        />
    }

    saveName = () => {
        updateString(this.props.firebase.database().ref(`/WAYPOINTS/${this.props.waypoint}/name`), this.state.name).then(
            this.setState({editName: false})

        )

    }
    saveCategory = value => {

        updateString(this.props.firebase.database().ref(`/WAYPOINTS/${this.props.waypoint}/category`), value).then(this.setState({
            chooseCategory: false,
            category: value
        }))
    }

    render() {

        if (!this.state.lat) {
            return <CircularProgress size={16} thickness={5}/>
        }
        const editName = this.state.editName && this.state.name ?
            <div style={{display: 'inline-flex', alignItems: 'center'}}>
                <TextField value={this.state.name} onChange={this.handleNameChange} key={"name-text"}/>
                <FlatButton icon={<Save/>} onClick={this.saveName}/>
                <FlatButton icon={<Cancel/>} onClick={this.toggleEditName}/>
            </div>
            :
            <div style={{display: 'inline-flex'}}>{this.state.name} {this.props.permissions.editWaypoint && <FlatButton icon={<Edit/>} labelPosition="before"
                                                                                onClick={this.toggleEditName}/>}
            </div>

        return (

            <div style={{padding: '.5rem'}}>
                <h1>{editName}{this.state.chooseCategory ? <div>

                        <FlatButton label='Close' icon={<Cancel/>} onClick={this.toggleEditCategory}/>

                        <CategoryPicker firebase={this.props.firebase}
                                        onChange={this.saveCategory}

                        /></div> :
                    this.props.permissions.editWaypoint && (
                    <RaisedButton primary={true} onClick={this.toggleEditCategory}><CategoryIcon type={this.state.category} color={"#fff"}/>
                    </RaisedButton>)}
                </h1>
                {this.renderLog()}


            </div>
        )
    }
}

