import React, {Fragment, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import PropTypes from 'prop-types'

import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import CircularProgress from 'material-ui/CircularProgress';
import {FlatButton} from "material-ui";


export default function SurveyMenu(props) {
    console.log('<SurveyMenu />')
    const [surveys, setSurveys] = useState({})
    const [loading, setLoading] = useState(false)
    const [value] = useState('list');
    const history = useHistory()


    const updateSurveys = snapshot => {
        console.log("Updating surveys", snapshot.val())
        setSurveys(snapshot.val())
        setLoading(false)
    }
    useEffect(() => {
        console.log("<SurveyMenu />", surveys, loading)
            if (!Object.keys(surveys).length && !loading) {
                console.log("<SurveyMenu /> Fetching surveys")
                setLoading(true)
                let ref = props.firebase.database().ref("SURVEYS")

                ref.on('value', snapshot => {
                    console.log("Updating survey", snapshot)
                    updateSurveys(snapshot)

                })

            }
        }
    )

    const surveyButton = (survey) => {
        console.log(survey)
        return (
            <MenuItem key={survey}
            >
                <FlatButton onClick={()=> {
                    history.push(`/survey/${survey}`)
                }}  style={{color: "#FFF"}}>{surveys[survey].NAME}</FlatButton>

            </MenuItem>
        )


    }
        if (loading || !surveys) {
            return <CircularProgress/>
        }

        const buttons =Object.keys(surveys)?.map(survey => {
            return surveyButton(survey)
        })




        return (
            <Fragment>

                <DropDownMenu  value={value}>
                    <MenuItem value="list" primaryText="Surveys" style={{color: "#fff"}}/>
                    {props.user.userLevel >= 99 && (
                        <MenuItem><button onClick={()=> {
                            history.push(`/add/survey`)
                        }} style={{color: "#FFF"}}>Add a survey</button>
                        </MenuItem>
                    )}

                    {buttons}
                </DropDownMenu>
            </Fragment>


        )

}



SurveyMenu.propTypes = {
    addSurvey: PropTypes.func,
}