import React, {Fragment, useState, useEffect, useRef} from 'react';
import Participant from './components/Participant/Participant';
import UserComponent from './components/User/UserComponent'
import AddParticipant from './components/Participant/AddParticipant'
import SurveyCard from './components/Surveys/SurveyCard'
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MainAppBar from "./components/Nav/Nav";
import firebase from 'firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
import FirebaseAuth from 'react-firebaseui/FirebaseAuth'
import {cyan800, teal500, white} from "material-ui/styles/colors"
import {BrowserRouter, Route, Switch, useHistory, useParams, HashRouter} from 'react-router-dom'
import NewUser from './pages/User/NewUser'
import Prompts from './pages/Prompts'
import logo from './img/baglogo.png'
import {PERMISSIONS} from './utils/Constants'

// export default class App extends Component {
export default function App(props) {
    const [userLevel, setUserLevel] = useState()
    const [auth, setAuth] = useState(false)
    const [user, setUser] = useState(null)
    const [initialized, setInitialized] = useState(false)
    const [FIREBASE, setFIREBASE] = useState()
    // let secondaryApp = null
    const [secondaryApp, setSecondaryApp] = useState()
    let firebaseInitialized = useRef(false)
    let history = useHistory();
    let muiTheme = getMuiTheme({
        palette: {
            canvasColor: teal500,
            accent2Color: cyan800,
            textColor: white,
            backgroundColor: "#333",

        },

        appBar: {
            height: 50,
        },
        height: '100%',
    })

    //AIzaSyAtH3rFvxqMSUTfxPdbcnyRdUDNLDnmyIk

    const config = {
        apiKey: "AIzaSyAtH3rFvxqMSUTfxPdbcnyRdUDNLDnmyIk",
        authDomain: "expanded-league-678.firebaseapp.com",
        databaseURL: "https://expanded-league-678.firebaseio.com",
        projectId: "expanded-league-678",
        storageBucket: "expanded-league-678.appspot.com",
        messagingSenderId: "658810756226",
        appId: "1:658810756226:web:2e7c605c801ff60397e89b",
        "project_number": "658810756226",
        "firebase_url": "https://expanded-league-678.firebaseio.com",

        "project_id": "expanded-league-678",
        "storage_bucket": "expanded-league-678.appspot.com"

    }

    function cleanupDuplicateParts() {
        // console.log("Cleaning up duplicates")
        // let deviceIDs = []
        // const ref = firebase.database().ref("/PARTICIPANTS").orderByChild("deviceID").on('value', snapshot=> {
        //     let participants = snapshot.val()
        //     console.log('participants', participants)
        //     if(!snapshot.val()) {
        //         return
        //     }
        //     Object.keys(participants).map(key => {
        //         console.log('participant', key)
        //         let participant = participants[key]
        //         if (deviceIDs.includes(participant.deviceID)) {
        //             console.log("Device ID exists, going to delete")
        //             firebase.database().ref(`/PARTICIPANTS/${key}`).remove()
        //             // ref.child(key).remove()
        //         } else {
        //             deviceIDs.push(participant.deviceID)
        //         }
        //     })
        //
        // })

        //Get all the deviceIDs

        // ref.orderByChild("deviceID").equalTo("hog2fjrvab1tcfr6njv617vn9m").on('value', snapshot=> {
        //     if(!snapshot.val()) {
        //         return
        //     }
        //     let keys = Object.keys(snapshot.val())
        //     keys.map(key => {
        //         //console.log(key)
        //         ref.child(key).remove()
        //     })
        // })
    }

    useEffect(() => {
        if (!initialized) {

            console.log("Initializing Firebase")
            firebase.initializeApp(config);
            console.log("Updating initialization")
            setInitialized(true)

            //TODO: register listener
            firebase.auth().onAuthStateChanged(user => {
                //console.log(user)
                if (user) {
                    console.log(user)
                    //TODO: grab user credentials
                    // setState({auth: true, user})
                    setAuth(true)
                    setUser(user)

                    if (user.email) {
                        getSessionObject(user)

                    }

                    window.firebase = firebase
                    setFIREBASE(firebase)

                }
            })

        }
        if(!secondaryApp) {
            try {
                console.log("Attempting to initialize secondary app")
                setSecondaryApp(firebase.initializeApp(config, 'Secondary'));
            } catch (e) {
                console.log("Couldn't set up secondary app")
                console.log(e)
            }
        }
    });


    function getSessionObject(user) {
        if (!user) {
            return
        }
        const ref = firebase.database().ref("/USERS")
        ref.orderByChild("email").equalTo(user.email).on('value', snapshot => {
            if (!Object.keys(snapshot.val()).length) {
                //No user found
                firebase.auth().signOut()
            }
            let u = snapshot.val()[Object.keys(snapshot.val())[0]]

            if (!u) {
                //console.log("No use session found, returning")
                return
            }
            //console.log(u)


            if (u.userLevel >= 99) {
                cleanupDuplicateParts()
            }
            let perm = Object.assign({}, PERMISSIONS, u.permissions || {})
            if (u.userLevel >= 99) {
                perm.user.view = true
                perm.user.edit = true
            }
            u.permissions = perm
            setUser(u)
            // setState({user: u})

        })

    }

    const renderUnauth = () => {
      if(!initialized) {
          console.log("Not initialized")
          return
      }
        let authConfig = {
            signInSuccessUrl: '/',
            signInFlow: 'popup',
            signInOptions: [
                firebase.auth.EmailAuthProvider.PROVIDER_ID,

            ],
        }

        return (
            <div style={{marginLeft: '40%', width: '20rem'}}>

                <img src={logo} style={{height: '20rem', width: '20rem'}}/>
                <FirebaseAuth uiConfig={authConfig} firebaseAuth={firebase.auth()}/>

                {/*<RaisedButton primary={true}>Forgot Password</RaisedButton>*/}


            </div>
        )
    }

    let backgroundStyle = {
        backgroundColor: teal500,
        height: "100%"
    };

    console.log("history", history)

    return (<HashRouter>
            <MuiThemeProvider muiTheme={muiTheme} style={{height: '100%', background: logo}}>

                <div className="App" style={backgroundStyle}>
                    <MainAppBar title="Talk Around Town"

                                userLevel={userLevel || 10}
                                firebase={firebase}
                        // participants={user.participants}
                                history={history}
                                user={user}
                    />
                    {auth ?
                        (
                            <Fragment>

                                <Switch>


                                    <Route path='/participant/add'
                                           render={(props) => (<AddParticipant {...props} user={user}
                                                                               firebase={firebase}

                                                                               permissions={user && user.permissions && user.permissions.participant || {}}
                                           />)}

                                    />
                                    <Route path='/participant/:participant/:waypoint?/:waypointid?/:log?'
                                           render={(props) => (<Participant {...props} user={user}
                                                                            firebase={firebase}

                                                                            permissions={user && user.permissions && user.permissions.participant || {}}

                                           />)}


                                    />

                                    <Route path={'/user/add'}
                                           render={(props) => <NewUser {...props} user={user}
                                                                       permissions={user && user.permissions && user.permissions.user || {}}
                                                                       firebase={firebase}
                                                                       secondaryApp={secondaryApp}/>}/>
                                    <Route path={'/user/:user'}
                                           render={(props) => <UserComponent {...props} user={user}
                                                                             permissions={user && user.permissions && user.permissions.user || {}}
                                                                             firebase={firebase}/>}/>
                                    <Route path={'/add/survey'}
                                           render={(props) => <SurveyCard {...props} user={user}
                                                                          permissions={user && user.permissions && user.permissions.survey || {}}
                                                                          firebase={firebase} add={true}/>}/>
                                    <Route path={'/survey/:survey'}
                                           render={(props) => <SurveyCard {...props} user={user}
                                                                          permissions={user && user.permissions && user.permissions.survey || {}}
                                                                          firebase={firebase}/>}/>

                                    <Route path={'/category/:category'}
                                           render={(props) => <Prompts {...props} user={user}
                                                                       permissions={user && user.permissions && user.permissions.prompt || {}}
                                                                       firebase={firebase}/>}/>
                                </Switch>
                            </Fragment>
                        )
                        : renderUnauth()}


                </div>

            </MuiThemeProvider></HashRouter>

    );
    // }
}

// module.exports = App;
window.App = App;