import React, {Component} from 'react';

import RaisedButton from 'material-ui/RaisedButton';
import CircularProgress from 'material-ui/CircularProgress';
import {Link} from 'react-router-dom'
import {teal100} from 'material-ui/styles/colors'
import WaypointsMap from "./WaypointsMap";
import Drawer from 'material-ui/Drawer';
//AutoComplete stuff
import PlacesAutocomplete, {geocodeByAddress, getLatLng,} from 'react-places-autocomplete';

export default class WaypointList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            participant: null,
            apiKey: null,
            selectedWaypoint: null,
            value: null,
            loading: true,

        }
    }

    loadWaypoints = () => {
        //console.log(this.props)
        let ref = this.props.firebase.database().ref(`WAYPOINTS/`)

        ref.orderByChild("participant").equalTo(this.props.participant).on('value', snapshot => {
            //console.log(snapshot.val());

            this.setState({waypoints: snapshot.val(), loading: false})
        })
    }

    UNSAFE_componentWillMount() {

        this.loadWaypoints()

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if (prevProps.participant != this.props.participant) {
            this.loadWaypoints()
        }
    }
    waypointButton = (obj, key, index) => {
        return (
            <div style={{padding: '.5rem'}}>
                <Link to={`/participant/${this.props.match.params.participant}/waypoint/${key}`}
                      style={{padding: '1rem', color: '#333'}}>{obj.name.toUpperCase()}</Link>
            </div>
        )
    }
    toggleAddWaypoint = () => {
        this.setState({addWaypoint: !this.state.addWaypoint})
    }
    addWaypoint = address => {
        //console.log(this.state)

        let waypoint = {
            id: "",
            lat: this.state.lat.toString(),
            lon: this.state.lng.toString(),
            name: address,
            participant: this.props.participant,
            category: "restaurant",
            prompts: null,
            radius: '100',
        }
        let ref = this.props.firebase.database().ref('WAYPOINTS')
        ref.push(waypoint)
        this.setState({addWaypoint: false})


    }

    handleAddressChange = address => {
        //TODO:

        this.setState({address});
    };

    handleSelect = address => {

        geocodeByAddress(address)
            .then(results => {

                getLatLng(results[0])
                    .then(latLng => {
                        //console.log(latLng)
                        this.setState(latLng)
                        this.addWaypoint(address)
                    })
                    .catch(error => console.error('Error', error));
            })

    };

    render() {
        //console.log(this.props)
        let waypoints = (this.state.waypoints) ? Object.keys(this.state.waypoints).map((key, index) => {


            return this.waypointButton(this.state.waypoints[key], key)
        }) : null;
        if (!waypoints && this.state.loading) {
            return <div>
                <CircularProgress/>
            </div>
        }
        return (
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '20%', background: `${teal100}`, alignContent: 'center', padding: "2rem"}}>

                    {this.state.addWaypoint ?
                        <PlacesAutocomplete
                            value={this.state.address}
                            style={{'height': '10rem'}}
                            onChange={this.handleAddressChange}
                            onSelect={this.handleSelect}
                        >
                            {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
                                <div>
                                    <input
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                        })}
                                        style={
                                            {
                                                'height': '2rem',

                                                borderRadius: '3px',
                                                padding: '0.2rem',
                                                border: "none",
                                                width: '100%'

                                            }

                                        }
                                    />
                                    <div className="autocomplete-dropdown-container"

                                    >
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                                                : {backgroundColor: '#ffffff', cursor: 'pointer'};
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                        className,
                                                        style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                        :
                        <div>
                            {this.props.permissions.addWaypoint && (
                            <RaisedButton label="Add Waypoint" primary={true} onClick={this.toggleAddWaypoint}/>
                            )}

                        </div>
                    }
                    <div>

                        {waypoints}

                    </div>
                </div>
                <WaypointsMap waypoints={this.state.waypoints || []}/>
            </div>


        )
    }

}
