import React, {useState} from 'react';
import {differenceInDays, differenceInMinutes, format, isSameDay, parse} from 'date-fns'
import Stars from 'material-ui/svg-icons/action/stars'
import {green500, yellow500} from 'material-ui/styles/colors'

//TODO: probably remake this as a component so we can handle the lifecycle methods easier
export default function WaypointLog({db, waypointId, promptsRef, category}) {

    const [waypointLog, setWaypointLog, showPrompts, setShowPrompts] = useState([])
    const promptsArray = []
    let prompts
    promptsRef.on('value', snapshot=> {
        if(!snapshot || !snapshot.val()) {
            console.log("No prompts")
            return;
        } else {
            console.log("Found Prompts")
        }
        console.log(snapshot.val())
        prompts = snapshot.val()

    })
    db.on('value', snapshot => {

        if (!snapshot || !snapshot.val()) {
            return
            //console.log('no data')
        }
        let log = []


        const keys = Object.keys(snapshot.val())

        keys.map(key => {
            log.push(snapshot.val()[key])

        })
        log.sort((a, b) => {
            if (a.timeEntered < b.timeEntered) {
                return 1
            } else
                return -1
        })

        //This is hackey, but works - prevents constant rerenders

        if (typeof log == "array" && log.join(",", "") != waypointLog.join(",", "")) {
            setWaypointLog(log)
        } else if(typeof log =="object" && JSON.stringify(log) != JSON.stringify(waypointLog)) {
            setWaypointLog(log)
        }

    })

    /**
     * Not sure this is necessary?
     * */
    const formatDates = (first, second = Date.now()) => {
        console.log('formatDates', first, second)
         //console.log('formatDate', first, second)
        try {
            if (isSameDay(first, second)) {

                return `${format(first, 'LL/d/Y - k:mm')} - ${format(second, 'k:mm')}`
            } else {
                return `${format(first, 'LL/d/Y - k:mm')} - ${format(second, 'LL/d - k:mm')}`
            }
        } catch (e) {
            console.log(e)
             return `Invalid Date ${format(first, 'LL/d/Y - k:mm')} -> ${second}`
        }

    }
    const returnDuration = (first, second) => {
        console.log('returnDuration', first, second)
        if (Math.abs(differenceInDays(first, second)) < 1) {
            if (Math.abs(differenceInMinutes(first, second) > 60)) {

                let hours = Number(differenceInMinutes(first, second) / 60).toFixed(2)
                return `${hours} hours`
            } else {

                return `${differenceInMinutes(first, second)} minutes`
            }
        }
    }

    const LogItem = props => {
        try {
            let {entry} = props
            console.log(entry)
            console.log('<LogItem />', entry.promptsDisplayed)
            let promptsDisplayed
            try {
                promptsDisplayed = JSON.parse(entry?.promptsDisplayed) || entry?.promptsDisplayed.split(",")

            } catch {
                promptsDisplayed = entry?.promptsDisplayed.split(",")
            }
            let entered = new Date(Number(entry?.timeEntered))

            console.log(entered)
            let {lastCheckIn, lastCheckin} = entry
            if (!lastCheckin) {
                lastCheckin = new Date(0)
            } else {
                lastCheckin = new Date(Number(lastCheckin));
            }

            const thereNow = differenceInMinutes(Date.now(), lastCheckin) < 5
            console.log(differenceInMinutes(Date.now(), lastCheckin))


            let categoryPrompts = prompts[category]?.prompts || [];

            return <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                'padding': '1rem',
                boxShadow: "6px 6px 16px 2px rgba(0,0,0,0.22)",

            }} onClick={() => {
            }}>
                <div style={{
                    fontWeight: "700",
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',

                }}>
                    {thereNow && <div style={{
                        display: 'inline-flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        fontSize: '2rem'
                    }}><Stars color={yellow500}/> - Currently Here</div> || <div/>}
                    {entered && format(entered, 'LL/d/Y - k:mm')}
                    ->
                    {format(lastCheckin, 'LL/d/Y - k:mm')}

                    ({returnDuration(lastCheckin, entered)})


                </div>
                <div>
                    {promptsDisplayed?.map((prompt, index) => {
                        console.log("prompt", prompt)
                        if (!prompt?.name) {
                            console.log("Sent as an array, probably iOS", categoryPrompts)

                            if (Object.keys(categoryPrompts)) {
                                console.log("Searching prompts for this category")
                                Object.keys(categoryPrompts).map(id => {

                                    if (id == prompt) {
                                        prompt = categoryPrompts[id]?.name
                                    }
                                })
                            }

                        }
                        return <div
                            style={{padding: '1rem', display: 'flex', 'justifyContent': 'space-between', width: '95%'}}>
                            <div>{Object.keys(prompt).length ? prompt?.name: prompt }</div>
                            <div>({entry[`prompt${index + 1}Metric`]} clicks)</div>
                        </div>
                    })}
                </div>
                {/*<div>{JSON.stringify(entry)}</div>*/}
            </div>
        } catch(e) {
            return <div>There was an error formatting this prompt</div>
        }
    }
    const renderLog = () => {

        return <div>
            {waypointLog.map((entry, index) => {
                console.log(entry.promptsDisplayed)
               return <LogItem entry={entry} key={index} category={category}/>


            })}
        </div>

    };


    return (<div>

        {renderLog()}
    </div>)

}