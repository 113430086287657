import React, {Component} from 'react'
import Paper from "material-ui/Paper";
import {grey900, teal300, deepOrange800, orangeA700} from "material-ui/styles/colors";
import {Card} from "material-ui";
import PropTypes from 'prop-types'
import ERROR from 'material-ui/svg-icons/alert/error'
// import {teal200, deepOrange800, teal300} from 'material-ui/styles/colors'

export default class ErrorComponent extends Component{

    render() {

        let style = {
            "borderRadius": "3px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "background": orangeA700,
            "color": "#FFF",
            width:"100%"
        };
        let paperStyle = {
            "display": "flex",
            flexDirection: "column",
            "color": "#FFF",
            "backgroundColor": deepOrange800,
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            "borderRadius": "3px",
            width: "90%",
            margin: 10,
        }
        return <Paper zDepth={3} style={paperStyle}>
            <ERROR style={{height: "3rem", width: "3rem"}}/>

            <Card  style={style}>
                {this.props.children}
            </Card>

        </Paper>
    }


}

ErrorComponent.propTypes = {
    children: PropTypes.node.isRequired

}