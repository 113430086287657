/**
 * Created by THOR on 7/21/2019.
 */

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import BaseCard from '../Card/BaseCard'
import RaisedButton from 'material-ui/RaisedButton'
import QuestionComponent from './QuestionComponent'
import TextField from "material-ui/TextField";

export default class SurveyCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            NAME: null,
            PROGRAM: null,
            CREATEDBY: null,
            QUESTIONS: [],
            modified: false,
        }
    }

    componentDidMount() {

        this.loadSurvey()
    }
    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({NAME: null, PROGRAM: null, QUESTIONS: [], modified: false})
        this.loadSurvey()
    }

    saveSurvey = () => {
        let survey = {...this.state}
        delete survey.modified
        if(!this.props.add) {
            let ref =this.props.firebase.database().ref(`SURVEYS/${this.props.match.params.survey}`)
            ref.set(survey);
        }  else {
            this.props.firebase.database().ref('SURVEYS/').push().set(survey)
        }


    }
    loadSurvey() {
        let survey = this.props.firebase.database().ref(`SURVEYS/${this.props.match.params.survey}`)

        survey.on('value', snapshot => {
            this.setState(snapshot.val())
        })

    }

    handleChange = obj => event => {
        let QUESTIONS = this.state.QUESTIONS
        QUESTIONS.map(question => {
            if (question.TITLE == obj.title) {
                //console.log('UPDATING')
                question.OPTIONS[question.OPTIONS.indexOf(obj.option)] = event.target.value
            }
        })
        this.setState(state => {
                state.QUESTIONS = QUESTIONS
            }, this.update()
        )

    }
    update = () => {
        //console.log(this.props.survey)
        //console.log(this.state)

            this.props.firebase.database().ref(`SURVEYS/${this.props.survey}`).set(
                this.state
            )

    }
    //TODO: dropdown to select program
    render() {
        console.log('<SurveyCard />', this.props.permissions)
        if(!this.props.permissions.add) {
            return <div>Unauthorized</div>
        }
        return (
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                <BaseCard title={

                    <TextField id={'name'} multiLine={true}
                               defaultValue={this.state.NAME}
                               floatingLabelText={'Survey Name'}
                               onChange={e => {
                                   this.setState({NAME: e.target.value, modified: true})
                               }}

                    />

                } subtitle={
                    <div>{this.state.PROGRAM} | {this.state.CREATEDBY || (this.props.add && this.props.user.email)}</div>

                }

                    style={{width: '100%', alignItems: 'center', justifyContent: 'center'}}>

                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '1rem'}}>
                        <div style={{padding: '1rem'}}>
                            <RaisedButton primary={true} onClick={()=> {
                                let QUESTIONS = this.state.QUESTIONS
                                QUESTIONS.push({
                                    TYPE: 'TEXT',
                                    OPTIONS: []

                                })
                                this.setState({QUESTIONS})

                            }}><span style={{padding: '1rem'}}>Add Question</span></RaisedButton>
                            {this.state.modified && <RaisedButton secondary={true} onClick={()=> {
                                this.saveSurvey()
                            }}>Save</RaisedButton>}

                        </div>
                        {this.state.QUESTIONS.map((question, index) => {


                            return <QuestionComponent key={index}
                                                      question={question}
                                                      handleUpdate={(question, key)=>{
                                    let QUESTIONS = this.state.QUESTIONS.slice()
                                                          QUESTIONS[index] = question

                                                          this.setState({QUESTIONS, modified: true})
                                }
                            } />

                        })}
                    </div>
                </BaseCard>
            </div>

        )
    }
}

SurveyCard.propTypes = {
    survey: PropTypes.string,
    firebase: PropTypes.object,

}